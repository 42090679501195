
import {defineComponent, reactive, ref, toRefs, watch} from "vue";
import {message, Modal} from "ant-design-vue";

export default defineComponent({
  name: "Jiangli",
  components:{
    AModal: Modal,
  },
  props: {
    visible: {
      type: Boolean,
    },
    isAll: {
      // 是否是给所有学员发放
      type: Boolean,
      default: false,
      required: false,
    },
    oneStudent: {
      // 单个学员发放数据
      type: Object,
      required: false,
      default: () => {

      }
    }
  },
  setup(props, context) {
    let jiangliVisible:any = ref(false);
    let jiangliRef:any = ref(null);
    let stateRef:any = reactive({

    })

    let refData = toRefs(stateRef)
    watch(() => props.visible, v => {
      jiangliVisible.value = v;
      if (v){
        // message.info('2s后自动关闭...', 2)
        setTimeout(()=>{
          jiangliVisible.value = false;
        }, 2000)
      }
    })
    watch(() => jiangliVisible.value, (v) => {
      context.emit('update:visible', v);
    })

    return {
      ...refData,
      jiangliRef,
      jiangliVisible,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
