import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "timetable-progress__box1 timetable-progress__box" }
const _hoisted_2 = { class: "timetable-progress__linebox" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["data-id"]
const _hoisted_5 = {
  key: 0,
  class: "ic zhi"
}
const _hoisted_6 = {
  key: 1,
  class: "ic yin"
}
const _hoisted_7 = {
  key: 2,
  class: "ic hua"
}
const _hoisted_8 = { class: "timetable-progress__box2 timetable-progress__box" }
const _hoisted_9 = {
  key: 0,
  class: "zhi"
}
const _hoisted_10 = {
  key: 1,
  class: "yin"
}
const _hoisted_11 = {
  key: 2,
  class: "hua"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "timetable-progress",
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => ($data.showProgress = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => ($data.showProgress = false))
  }, [
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn animate__faster",
      "leave-active-class": "animate__animated animate__fadeOut animate__faster"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.coursewareState, (i, k) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "timetable-progress__item",
                key: k,
                style: _normalizeStyle({width: `calc(${100 / ($setup.coursewareState.length - 1) + '%'} - 16px)`}),
                onClick: ($event: any) => ($setup.onPageChange(i, k))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["timetable-progress__prs", {
              'active': $setup.coursewareIndex> k,
              'video': /(mp4|3gp)/.test(i.mediaType.toLowerCase()),
              'now': $setup.coursewareIndex === k
            }]),
                  ref_for: true,
                  ref: $setup.prsRef,
                  "data-id": 'prs'+ k
                }, [
                  (i.typeName === $setup.ECourseType.zhi)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                    : (i.typeName === $setup.ECourseType.yin)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                      : (i.typeName === $setup.ECourseType.hua)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                        : _createCommentVNode("", true)
                ], 10, _hoisted_4)
              ], 12, _hoisted_3))
            }), 128))
          ]),
          _createElementVNode("div", {
            class: _normalizeClass([$setup.fullscreenState?'eye-open':'eye-close', "timetable-progress__eye"]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onFullChange && $setup.onFullChange(...args)))
          }, null, 2)
        ], 512), [
          [_vShow, $data.showProgress]
        ])
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn animate__faster",
      "leave-active-class": "animate__animated animate__fadeOut animate__faster"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.coursewareState, (i, k) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["timetable-progress__prs", {'ic': i.typeName, 'active': $setup.coursewareIndex >= k}])
            }, [
              (i.typeName === $setup.ECourseType.zhi)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                : (i.typeName === $setup.ECourseType.yin)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                  : (i.typeName === $setup.ECourseType.hua)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                    : _createCommentVNode("", true)
            ], 2))
          }), 256))
        ], 512), [
          [_vShow, !$data.showProgress]
        ])
      ]),
      _: 1
    })
  ], 32))
}