
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent({
  name: "BackgroundImgChange",
  data() {
    return {}
  },
  props: {
    width: {
      type: [String, Number],
      required: false,
      default: 30,
    },
    height: {
      type: [String, Number],
      required: false,
      default: 30,
    },
    src: {
      type: [String],
      required: false,
      default: '',
    },
    dataSrc: {
      type: [String],
      required: false,
      default: '',
    },
  },
  mounted() {
    // this.init();
  },
  setup(props){

    let imgRefDom: any = ref(null);


    const imgRef = (el: HTMLElement) => {
      imgRefDom = el;
    }

    const onMouseOver = () => {
      imgRefDom.style.background = `url('${props.dataSrc}') no-repeat`
      imgRefDom.style.backgroundSize = `100% 100%`
    }

    const onMouseLeave = () => {
      imgRefDom.style.background = `url('${props.src}') no-repeat`
      imgRefDom.style.backgroundSize = `100% 100%`
    }

    onMounted(()=>{
      imgRefDom.style.background = `url('${props.src}') no-repeat`
      imgRefDom.style.backgroundSize = `100% 100%`
    })




    return {
      imgRef,
      onMouseOver,
      onMouseLeave,
    }
  },
  methods: {

  },
})
