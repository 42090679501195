
import {defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import {Modal} from "ant-design-vue";
import {CloseCircleOutlined, SyncOutlined} from "@ant-design/icons-vue";
import service from "@/utils/axios";
import {useRoute} from "vue-router";
import DwAvatar from "@/components/system/DwAvatar.vue";

export default defineComponent({
  name: "Xiang",
  props: {
    visible: {
      type: Boolean,
    },
    rules: {
      type: Array,
      required: true,
    }
  },
  components: {
    DwAvatar,
    AModal: Modal,
    CloseCircleOutlined,
    SyncOutlined
  },
  setup(props, context){
    let xiangVisible:any= ref(false);
    let xiangRef:any = ref(null);

    const route = useRoute();
    let stateRef = reactive({
      loading: false,
      fenxiangStu: <any>{
        avatar: null,
        studentId: '',
        studentName:'',
        listModels:[],
      },
    })

    let refData:any = toRefs(stateRef)
    let avatar:any = ref('');
    let name:any = ref('');
    let canSend:any = ref(false);
    let timeout;


    let onReward = (item: any) => {
      if (canSend.value){
        context.emit('jiangli', item, stateRef.fenxiangStu);
      }
    }


    const getShare = async (type?: string) => {
      /**
       * 抽奖或分享
       * extractType    1: 抽奖  2: 分享
       */
      if (type ==='re' && !canSend.value){
        return;
      }
      const r: any = await service.post('/dwart/an_teaching/classCourse/extractStudents', {
        classId: route.query.classId,
        classScheduleId: route.query.scheduleId,
        scheduleIndexStr: route.query.courseIndex,
        extractType: 2,
      })
      stateRef.fenxiangStu = r;
      await setAvatar()
    }
    const setAvatar = async () => {
      canSend.value = false
      timeout = setInterval(()=>{
        let randomint = parseInt((Math.random()* stateRef.fenxiangStu.listModels.length).toString(), 10)
        avatar.value = stateRef.fenxiangStu.listModels[randomint]?.avatar;
        name.value = stateRef.fenxiangStu.listModels[randomint]?.studentName;
      }, 200)
      setTimeout(()=>{
        clearInterval(timeout)
        avatar.value = stateRef.fenxiangStu.avatar;
        name.value = stateRef.fenxiangStu.studentName;
        canSend.value = true
        let audio = document.createElement('audio')
        audio.src = require('@/assets/audio/奖励.wav');
        audio.play();
      }, 3100)
    }

    // @ts-ignore
    watch(()=>props.visible, v=>{
      xiangVisible.value = v;
      if (v){
        getShare();
      }
    })
    // @ts-ignore
    watch(()=>xiangVisible.value, (v)=>{
      if (!v){
        clearInterval(timeout)
      }
      context.emit('update:visible', v);
    })



    return {
      ...refData,
      xiangVisible,
      xiangRef,
      onReward,
      getShare,
      avatar,
      name,
      canSend,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
