import {useRoute, useRouter} from "vue-router";
import customMixins from "@/utils/customComponentAPI/keepaliveComponentAPI";
import {reactive, Ref, ref, toRefs, onMounted} from "vue";
import Swiper from "swiper";
import {useStore} from "vuex";
import menuShowInit from '@/utils/customComponentAPI/menuShowInit';
import service from "@/utils/axios";
import {onPageLoadingEnd, onPageLoadingStart} from "@/utils/DLoading";
import {ICourseware} from "@/store/module/courseware.module";
import {message} from "ant-design-vue";

export interface ICoursewareMenu {
    readonly img: string;
    readonly imgHover: string;
    readonly name: string;
    hidden: boolean;
    onClick: Function;
}


export interface ICoursewareMenus {
    menuList: Array<ICoursewareMenu>;
}

export interface ICoursewareLocalState extends ICoursewareMenus {
    // courseware组件的本地数据
    choujiangVisible: boolean;    // 抽奖的模态框
    jishiqiVisible: boolean;    // 计时器的模态框
    jiangliVisible: boolean;    // 奖励积分的模态框
    xiangVisible: boolean;    // 分享的模态框
    isAll: boolean;           // 是否给所有同学发放积分
    jiangliData: any;         // 奖励结果
    rules: any[];             // 所有的奖励规则
    pImg: any[]; //评价图片集合
    isShowPing: boolean, //评价图片是否隐藏
    showMenu: boolean,//工具栏显示隐藏
    pageIndex: number,//分页
    topicVideo:any[];//文字视频
    topicVideoShow:boolean,//汉字资源图片是否显示
}

export function coursewareBill() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const weixiaomo = async (i:object) => {
        localStorage.setItem("weixiaomo", JSON.stringify(i));
        await router.push({
            name:'weixiaomo',})
    }
    const gotoOtherPage = async (name: string) => {
        if (name !== 'video') {
            await router.push({
                name: name,
                query: {
                    from: 'courseware',
                    ...route.query,
                }
            })
        } else {
            stateRef.topicVideoShow = !stateRef.topicVideoShow
            await geTapIqueryTopicVideo()

        }

    }
    const onOutCourseware = async () => {
        await store.dispatch('actSetCourseware', []);
        await store.dispatch('actStarCountTimeState', 'asd')
        await router.back()
    }

    const stateRef = reactive({
        menuList: [
            {
                img: require('@/assets/image/timetable/ic_shangjzy_nor.png'),
                imgHover: require('@/assets/image/timetable/ic_shangjzy_hover.png'),
                name: "上节作业",
                hidden: true,
                onClick: () => gotoOtherPage('Homework')
            },
            {
                img: require('@/assets/image/timetable/ic_zidysc_nor.png'),
                imgHover: require('@/assets/image/timetable/ic_zidysc_hover.png'),
                name: "自定义素材",
                hidden: true,
                onClick: () => gotoOtherPage('MaterialTypes')
            },
            {
                img: require('@/assets/image/timetable/ic_jifb_nor.png'),
                imgHover: require('@/assets/image/timetable/ic_jifb_hover.png'),
                name: "积分榜",
                hidden: true,
                onClick: () => gotoOtherPage('LeagueTable')
            },
            {
                img: require('@/assets/image/timetable/ic_fengyb_nor.png'),
                imgHover: require('@/assets/image/timetable/ic_fengyb_hover.png'),
                name: "风云榜",
                hidden: true,
                onClick: () => gotoOtherPage('WindTable'),
            },
            {
                img: require('@/assets/image/timetable/youxi_nor.png'),
                imgHover: require('@/assets/image/timetable/youxi_hover.png'),
                name: "小游戏",
                hidden: true,
                onClick: () => gotoOtherPage('Game')
            },
            {
                img: require('@/assets/image/timetable/shiping_nor.png'),
                imgHover: require('@/assets/image/timetable/shiping_hover.png'),
                name: "视频",
                hidden: true,
                arrow: true,
                onClick: () => gotoOtherPage('video')
            }
            // {
            //   img: require('@/assets/image/timetable/ic_tuicdl_nor.png'),
            //   imgHover: require('@/assets/image/timetable/ic_tuicdl_hover.png'),
            //   name: "退出课件",
            //   hidden: false,
            //   onClick: onOutCourseware
            // },
        ],
        choujiangVisible: false,
        jishiqiVisible: false,
        jiangliVisible: false,
        xiangVisible: false,
        isAll: false,
        jiangliData: {studentName: '', integralCount: ''},
        rules: [],
        pImg: [],
        isShowPing: false,
        showMenu: false,
        pageIndex: 1,
        topicVideo:[],
        topicVideoShow:false,
    } as ICoursewareLocalState);

    const {setMenuInitByRoute} = menuShowInit();

    let refData = toRefs(stateRef)

    let swiperRefDomS: Ref<Array<any>> = ref([]);

    const swiperRefDom = (e: any) => {
        // @ts-ignore
        if (e && swiperRefDomS.value.findIndex(v => v.attributes['data-id'].value === e.attributes['data-id'].value) === -1) {
            // @ts-ignore
            swiperRefDomS.value.push(e)
        }
    }

    const onScrollChange = async (e) => {
        // 将前后的视频暂停，自动播放当前视频
        await store.dispatch('actCoursewareIndexState', e.activeIndex);
        // if (swiperRefDomS.value[e.activeIndex].nodeName.toLocaleLowerCase() === 'video') {
        //   swiperRefDomS.value[e.activeIndex].play();
        // }
        // if (e.activeIndex > 0 && swiperRefDomS.value[e.activeIndex - 1].nodeName.toLocaleLowerCase() === 'video') {
        //   swiperRefDomS.value[e.activeIndex - 1].pause();
        // }
        // if (swiperRefDomS.value[e.activeIndex + 1] && swiperRefDomS.value[e.activeIndex + 1].nodeName.toLocaleLowerCase() === 'video') {
        //   swiperRefDomS.value[e.activeIndex + 1].pause();
        // }
    }


    const onSwiperUpdate = async (a: any) => {
        console.log(2)
        // a.navigation.update();
    }

    const onSwiperNextStart = async (swiper: Swiper) => {
    }

    const onMenuShowChange = async (show: boolean,vla:number) => {
        stateRef.showMenu = show;
        if ( show === false && stateRef.topicVideoShow === true ) {
            stateRef.topicVideoShow  = false
        }
    }

    const setMenuShow = async (none: boolean = false) => {
        if (none) {
            stateRef.menuList.forEach(v => {
                if (v.name !== '退出课件') {
                    v.hidden = true
                }
            })
            return;
        }
        stateRef.menuList = setMenuInitByRoute(stateRef.menuList)

        // stateRef.menuList.forEach(v => {
        //   if (v.name !== '退出课件') {
        //     v.hidden = true
        //   }
        // })
    }

    const onReward = async (type: number, item?: any, stu?: any) => {
        // 奖励积分
        onPageLoadingStart('积分发放中, 请稍后...');
        let result: any;
        if (type === 1 || type === 2) {
            // 抽  享
            stateRef.choujiangVisible = false;
            stateRef.xiangVisible = false;
            let data: any[] = [];
            let _s = stu.listModels.find(v => v.studentId = stu.studentId);
            data.push({
                studentId: stu.studentId,
                classId: route.query.classId,
                classScheduleId: route.query.scheduleId,
                classCheckId: _s.classCheckId,
                studentName: stu.studentName,
                courseScheduleId: null,
                ruleConstantId: item.ruleConstantId,
                integralCount: item.integralOperation,
            });
            stateRef.isAll = false;
            result = await service.post(`/dwart/an_teaching/classCourse/bonusPoints/${route.query.scheduleId}/and/${type}`, data)
            if (result?.result) {
                stateRef.jiangliData = result.grantStudents[0];
            }
        } else if (type === 3) {
            // 奖
            result = await service.get(`/dwart/an_teaching/classCourse/prizeIntegra/${route.query.scheduleId}/and/${route.query.classId}`)
            stateRef.isAll = true;
        }
        if (result.result) {
            let audio = document.createElement('audio')
            audio.src = require('@/assets/audio/奖励.wav');
            await audio.play();
            stateRef.jiangliVisible = true;
            onPageLoadingEnd(result.message, 3)
        } else {
            message.error({content: result?.message ?? '积分发放失败!!', key: 'pageLoading', duration: 1})
        }
    }

    const onJiangli = async () => {

        // jiangliVisible = !jiangliVisible
        await onReward(3);
    }

    const omPing = async () => {
        stateRef.isShowPing = !stateRef.isShowPing
        await getApiqueryClassroomEvaluation(1)
    }
    const geTapIqueryTopicVideo = async () => {
        stateRef.topicVideo = await service.get(`/dwart/an_teaching/classCourse/queryTopicVideo/${route.query.topicId}`)
    }
    const getPPT = async () => {
        if (store.getters.getCoursewareState.length > 0) {
            await setMenuShow();
            return;
        }



        onPageLoadingStart()
        const r: { lessonPreparationList: Array<any>, teachList: Array<any> } = await service.get('/dwart/an_teaching/classCourse/searchResourcesAndByTopId', {
            params: {
                topicId: route.query.topicId
            }
        })

        let _coursewareList: Array<ICourseware> = r.teachList.map(v => {
            return {
                id: v.id,
                imgUrl: v.url,
                time: null,
                typeName: null,
                mediaType: v.mediaType
            }
        })
        const r2: Array<{ createTime: string, id: string, pageIndex: number, processTime: number, rawStatus: boolean, topicId: string, type: string }> = await service.get(`/dwart/an_teaching/classCourse/findDwTopicExtend/${route.query.topicId}`)

        r2.forEach(v => {
            let {type, pageIndex, processTime, rawStatus} = v;
            if (pageIndex > _coursewareList.length) {
                return;
            }
            if (rawStatus) {
                _coursewareList[pageIndex - 1].time = processTime
                _coursewareList[pageIndex - 1].typeName = type;
            }
        })


        await store.dispatch('actSetCourseware', _coursewareList)

        if (r.teachList.length === 0) {
            await setMenuShow(true)
        } else {
            await setMenuShow();
        }

        onPageLoadingEnd()


    }
    const getRules = async () => {
        stateRef.rules = await service.get(`/dwart/an_teaching/classCourse/selectIntegralRule/${route.query.classId}`)
    }

    const close = () => {
        stateRef.isShowPing = false
    }

    const pageInt = async (val: String) => {

        if (val === '加') {
            let lastPage = JSON.parse(JSON.stringify(stateRef.pImg)).lastPage
            if (!lastPage) {
                stateRef.pageIndex += 1
                await getApiqueryClassroomEvaluation(stateRef.pageIndex)
            } else {
                message.info('没有更多了~');
            }
        } else if( val === '减' ){
            if (stateRef.pageIndex !== 1 && stateRef.pageIndex > 1) {
                stateRef.pageIndex -= 1
                await getApiqueryClassroomEvaluation(stateRef.pageIndex)
            } else {
                message.info('已经是第一个页了~');
            }
        }else {
            await getApiqueryClassroomEvaluation(stateRef.pageIndex)
        }

    }

    const getApiqueryClassroomEvaluation = async (pageIndex: number) => {
        onPageLoadingStart()
        stateRef.pImg = await service.post(`/dwart/an_teaching/classCourse/queryClassroomEvaluation`, {
            "classScheduleId": route.query.scheduleId,//排课id
            "pageSize": 12,//每页记录数
            "pageIndex": pageIndex//开始页
        })
        onPageLoadingEnd()
    }
    const init = async () => {
        await getPPT();
        await getApiqueryClassroomEvaluation(1)
        if (route.query.from !== 'subject') {
            await getRules();
        }
    }

    return {
        onOutCourseware,
        onScrollChange,
        onSwiperUpdate,
        onSwiperNextStart,
        onMenuShowChange,
        onReward,
        setMenuShow,
        refData,
        swiperRefDomS,
        swiperRefDom,
        init,
        onJiangli,
        omPing,
        pageInt,
        close,
        weixiaomo,
    }
}
