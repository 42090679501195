
import {computed, defineComponent, nextTick, onBeforeUnmount, reactive, ref, toRefs, watch} from "vue";
import {message, Modal} from "ant-design-vue";
import {CloseCircleOutlined, SyncOutlined} from "@ant-design/icons-vue";
import moment from "moment";
import NumberChange from "@/components/timetable/NumberChange.vue";
import Hammer from 'hammerjs';

enum EJiShiQiType {
  "daojishi" = 1,
  "jishiqi" = 2
}

// 鼠标开始坐标
// 本次移动坐标
var deltaX = 0
var deltaY = 0
// 总移动坐标
var sumX = 0
var sumY = 0
var ham;


export interface IJishiqi {
  zujianVisible: boolean;     // 组件是否展示
  quanpinVisible: boolean;    // 计时器是否全屏展示
  showType: EJiShiQiType;     // 使用计时器还是倒计时 默认是倒计时
  jishiTimeSecond: number;    // 计时器的实际秒数
  jishiT: any;             // 计时器计时的标记
  daojishiTimeSecond: number; // 倒计时的秒数
  daojishiT: any;          // 倒计时的时间标记
}

export default defineComponent({
  name: "Jishiqi",
  components: {
    NumberChange,
    AModal: Modal,
    CloseCircleOutlined,
    SyncOutlined,
  },
  props: {
    visible: {
      type: Boolean,
    }
  },
  data() {
    return {}
  },
  setup(props, context) {
    const jishiqiRef = ref(null)

    let jishiTimeStr = computed(() => {
      let _m = moment.duration(stateRef.jishiTimeSecond, 'seconds')
      return moment({h: _m.hours(), m: _m.minutes(), s: _m.seconds()}).format('HH：mm：ss');
    })

    let daojishiTimeArray = computed(() => {
      let _m = moment.duration(stateRef.daojishiTimeSecond, 'seconds')
      return moment({h: _m.hours(), m: _m.minutes(), s: _m.seconds()}).format('mm：ss').split('：');
    })

    let stateRef = reactive(<IJishiqi>{
      zujianVisible: false,   // 组件是否展示
      quanpinVisible: false,   // 计时器|倒计时 是否全屏展示
      showType: EJiShiQiType.daojishi,
      jishiTimeSecond: 0,
      jishiT: null,
      daojishiTimeSecond: 600,
      daojishiT: null,
    })

    const resetNum = () => {
      // 销毁后清除所有信息
      deltaX = 0
      deltaY = 0
      sumX = 0
      sumY = 0
    }

    const moveDialog = (event: any) => {
      // 移动距离
      // 存储本次移动坐标
      const container: any = document.getElementsByClassName('move-dialog')[0]
      deltaX = event.deltaX
      deltaY = event.deltaY;
      container.style.transform = `translate(${deltaX + sumX}px, ${deltaY + sumY}px)`;
    }

    watch(() => props.visible, v => {
      stateRef.zujianVisible = v;
      if (props.visible) {
        nextTick(() => {
          const container: any = document.getElementsByClassName('move-dialog')[0]
          container.style.transform = `translate(0, 0)`
          const header = container.getElementsByClassName('ant-modal-header')[0];
          header.style.cursor = 'move'
          ham = new Hammer(header)
          ham.on('panmove', moveDialog)
          ham.on('panend', () => {
            console.log('end')
            sumX += deltaX;
            sumY += deltaY;
          })
        })

      } else {
        resetNum();
        ham.destroy()
      }
    })
    watch(() => stateRef.zujianVisible, (v) => {
      if (!v) {
        onJishi('re');
      }
      context.emit('update:visible', v);
    })

    watch(() => stateRef.showType, v => {
      if (v === EJiShiQiType.jishiqi) {
        // 使用计时器
      } else if (v === EJiShiQiType.daojishi) {
        // 使用倒计时
        onJishi('re');
      }
    })

    watch(() => stateRef.daojishiTimeSecond, v => {
      if (v === 0) {
        onOpt('pause');
        let audio = document.createElement('audio');
        audio.src = require('@/assets/audio/倒计时结束后播放.wav');
        audio.play();
      }
    })

    let refData = toRefs(stateRef)

    const onJishi = (type: string) => {
      if (type === 'pause') {
        if (stateRef.jishiT) {
          clearInterval(stateRef.jishiT);
          stateRef.jishiT = null
        } else {
          stateRef.jishiT = setInterval(() => {
            stateRef.jishiTimeSecond += 1
          }, 1000)
        }
      } else if (type === 're') {
        stateRef ? clearInterval(stateRef.jishiT) : null;
        stateRef.jishiT = null
        stateRef.jishiTimeSecond = 0;
      } else {

      }
    }
    const onDaojishi = (type: string) => {
      if (type === 'pause') {
        if (stateRef.daojishiT) {
          clearInterval(stateRef.daojishiT);
          stateRef.daojishiT = null
        } else {
          if (stateRef.daojishiTimeSecond === 0) {
            message.warn('请先设置时间');
            return;
          }
          stateRef.daojishiT = setInterval(() => {
            stateRef.daojishiTimeSecond -= 1
          }, 1000)
        }
      } else if (type === 're') {
        stateRef ? clearInterval(stateRef.daojishiT) : null;
        stateRef.daojishiT = null
        stateRef.daojishiTimeSecond = 600;
      } else {

      }
    }


    const onDaojishiAdd = (second: number) => {
      if (stateRef.daojishiT) {
        return;
      }
      if (stateRef.daojishiTimeSecond + second > 3599) {
        return;
      }
      stateRef.daojishiTimeSecond += second
    }
    const onDaojishiReduce = (second: number) => {
      if (stateRef.daojishiT) {
        return;
      }
      if (stateRef.daojishiTimeSecond < second) {
        return;
      }
      stateRef.daojishiTimeSecond -= second
    }


    onBeforeUnmount(() => {
      onJishi('re');
    })

    const onOpt = (type: string) => {
      if (stateRef.showType === EJiShiQiType.daojishi) {
        // 倒计时
        onDaojishi(type)
      } else if (stateRef.showType === EJiShiQiType.jishiqi) {
        // 计时器
        onJishi(type)
      }
    }

    const onQuanpin = () => {
      if ((stateRef.showType === EJiShiQiType.daojishi && stateRef.daojishiT) || (stateRef.showType === EJiShiQiType.jishiqi && stateRef.jishiT)) {
        stateRef.quanpinVisible = true
      }
    }

    const changeShowType = (e: any, type: EJiShiQiType) => {
      e.stopPropagation();
      stateRef.showType = type
    }

    return {
      ...refData,
      jishiqiRef,
      jishiTimeStr,
      daojishiTimeArray,
      EJiShiQiType,
      onOpt,
      onDaojishiAdd,
      onDaojishiReduce,
      onQuanpin,
      changeShowType,
    }
  },
  mounted() {

  },
  methods: {},
})
